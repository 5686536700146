.account-service-page {
  height: 100vh;
  padding-top: 69.75px;
  background: #fff url("./assets/bg.svg");
  background-size: cover;
  background-position: center;
}
@media only screen and (max-width: 767px) {
  .account-service-page {
    height: auto;
    min-height: 100vh;
  }
}
.card-container {
  margin-top: 77px;
}
.step-count {
  color: #03396c;
  opacity: 0.1;
  font-size: 90px;
  font-weight: 600;
}
.txt-1 {
  font-size: 20px;
}
.txt-2 {
  font-size: 18px;
}
.txt-3 {
  font-size: 22px;
}
ul.ul-list li {
  font-size: 16px;
  margin-bottom: 5px;
}
ul.ul-list li a {
  color: #b3b3b3;
}
ul.ul-list li.active a {
  color: #012345;
}
.form.form-beautiful input.form-control {
  background-color: #fff !important;
}
.form.form-beautiful .btn.btn-submit {
  background: #012345;
  color: #fff;
}
/*# sourceMappingURL=src/components/pages/accountService/AccountServicePage.css.map */