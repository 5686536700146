




















































































@import '~@/styles/variables'
.account-service-page
  height 100vh
  padding-top 69.75px
  background white url('./assets/bg.svg')
  background-size cover
  background-position center

  @media mobile
    height auto
    min-height 100vh

.card-container
  margin-top 77px

.step-count
  color blue-regal
  opacity 0.1
  font-size 90px
  font-weight 600

.txt-1
  font-size 20px

.txt-2
  font-size 18px

.txt-3
  font-size 22px

ul.ul-list
  li
    font-size 16px
    margin-bottom 5px
    a
      color grey-nobel

  li.active
    a
      color blue-cornflower

.form.form-beautiful
  input.form-control
    background-color white !important
  .btn.btn-submit
    background blue-cornflower
    color white
